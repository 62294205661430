import { AddressComponent } from './address/LocationPicker';
import { registerReactFormInputComponent, registerReactCustomDetailComponent } from '@vendure/admin-ui/react';
import { ColorPickerComponent } from './color-picker/color-picker.component';
export default [
    registerReactFormInputComponent('color-picker-compenent', ColorPickerComponent),

    registerReactCustomDetailComponent({
        locationId: 'channel-detail',
        component: AddressComponent,
    }),
];
