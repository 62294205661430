import {
    Card,
    FormField,
    useMutation,
    useDetailComponentData,
    useInjector,
    useRouteParams,
    useLazyQuery,
} from '@vendure/admin-ui/react';
import React, { useEffect } from 'react';
import GoogleAutoComplete from 'react-google-autocomplete';
import { GetLocationDocument, Location, UpdateLocationDocument } from '../generated/graphql';
import { NotificationService } from '@vendure/admin-ui/core';

export const AddressComponent = () => {
    const { params } = useRouteParams();
    const [data, setData] = React.useState<Location>();
    const [getData, { data: initial }] = useLazyQuery(GetLocationDocument, {
        refetchOnChannelChange: true,
    });
    const [update] = useMutation(UpdateLocationDocument);
    const notificationService = useInjector(NotificationService);
    const { entity } = useDetailComponentData();

    const fetchLocationData = async () => {
        const { channel } = await getData({
            channelId: params.id,
        });
        setData(channel?.location);
    };

    useEffect(() => {
        fetchLocationData();
    }, []);

    const handlePlaceSelected = async place => {
        try {
            const { updateLocation } = await update({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                location: place.formatted_address,
                channelId: params.id,
            });

            if (updateLocation?.id) {
                notificationService.success('Location updated successfully');
                await fetchLocationData();
            } else {
                notificationService.error('Location update failed');
            }
        } catch (error) {
            notificationService.error('An error occurred during the update');
        }
    };

    return (
        <Card>
            <FormField label="Address">
                <GoogleAutoComplete
                    defaultValue={entity?.customFields?.address}
                    apiKey="AIzaSyBIaprJgp3Lk5v1JXk8JdJimNHDhTkJCPw"
                    onPlaceSelected={handlePlaceSelected}
                    options={{
                        componentRestrictions: { country: 'us' },
                        types: ['geocode'],
                    }}
                    placeholder="Enter a location"
                />
            </FormField>

            <div style={{ marginTop: '1rem' }}>
                Selected Location: {data?.address} <br /> Lat: {data?.lat} <br /> Lng: {data?.lng}
            </div>
        </Card>
    );
};
