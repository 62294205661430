import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
    DataService,
    DeletionResult,
    isMultiChannel,
    ModalService,
    NotificationService,
    Permission,
    Product,
    registerBulkAction,
} from '@vendure/admin-ui/core';
import { unique } from '@vendure/common/lib/unique';
import { gql } from 'graphql-tag';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AssignProductsToChannel } from './components/assign-products-to-channel-dialog/assign-products-to-channel-dialog.component';

export default [
    registerBulkAction({
        location: 'product-list',
        label: _('common.assign-to-channel'),
        icon: 'layers',
        requiresPermission: userPermissions =>
            userPermissions.includes(Permission.UpdateCatalog) ||
            userPermissions.includes(Permission.UpdateProduct),
        isVisible: ({ injector }) => isMultiChannel(injector.get(DataService)),
        onClick: ({ injector, selection, clearSelection }) => {
            const ProductsGQL = gql`
                query GetProductsForBulkAction($options: ProductListOptions!) {
                    products(options: $options) {
                        items {
                            id
                            name
                            channels {
                                id
                                code
                            }
                        }
                    }
                }
            `;
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const channelIdsSet = new Set<string>();

            // Fetch products and channels
            dataService
                .query(ProductsGQL, {
                    options: {
                        filter: {
                            id: { in: unique(selection.map(p => p.id)) },
                        },
                    },
                })
                .mapSingle((data: any) => data.products.items)
                .subscribe((products: Product[]) => {
                    products.forEach(product => {
                        product.channels.forEach(channel => {
                            channelIdsSet.add(channel.id);
                        });
                    });

                    // Open the modal after channels are fetched
                    modalService
                        .fromComponent(AssignProductsToChannel, {
                            size: 'lg',
                            locals: {
                                productIds: unique(selection.map(p => p.id)),
                                currentChannelIds: Array.from(channelIdsSet),
                            },
                        })
                        .subscribe(result => {
                            if (result) {
                                clearSelection();
                            }
                        });
                });
        },
    }),
    registerBulkAction({
        location: 'product-list',
        label: _('common.delete'),
        icon: 'trash',
        iconClass: 'is-danger',
        requiresPermission: userPermissions =>
            userPermissions.includes(Permission.DeleteProduct) ||
            userPermissions.includes(Permission.DeleteCatalog),
        onClick: ({ injector, selection, hostComponent, clearSelection }) => {
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .dialog({
                    title: _('catalog.confirm-bulk-delete-products'),
                    translationVars: {
                        count: selection.length,
                    },
                    buttons: [
                        { type: 'secondary', label: _('common.cancel') },
                        { type: 'danger', label: _('common.delete'), returnValue: true },
                    ],
                })
                .pipe(
                    switchMap(response =>
                        response
                            ? dataService.product.deleteProducts(unique(selection.map(p => p.id)))
                            : EMPTY,
                    ),
                )
                .subscribe(result => {
                    let deleted = 0;
                    const errors: string[] = [];
                    for (const item of result.deleteProducts) {
                        if (item.result === DeletionResult.DELETED) {
                            deleted++;
                        } else if (item.message) {
                            errors.push(item.message);
                        }
                    }
                    if (0 < deleted) {
                        notificationService.success(_('catalog.notify-bulk-delete-products-success'), {
                            count: deleted,
                        });
                    }
                    if (0 < errors.length) {
                        notificationService.error(errors.join('\n'));
                    }
                    hostComponent.refresh();
                    clearSelection();
                });
        },
    }),
];
